/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
// eslint-disable-next-line import/extensions
import ButtonBase from '@mui/material/ButtonBase';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { CommonStyles } from '../../common/commonStyles.ts';
import { Tooltip } from '../Tooltip/Tooltip.tsx';

export type ButtonProps = {
  centerRipple?: boolean;
  children: React.ReactNode;
  dataTest?: string;
  disableRipple?: boolean;
  fill?: string;
  height?: string | number;
  hoverAlpha?: number;
  isEnabled?: boolean;
  isPressed?: boolean;
  margin?: string | number;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onDoubleClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  style?: SerializedStyles;
  tooltip?: string;
  width?: string | number;
};

/**
 * Provides standard clickable button behaviour.
 */
export const Button: React.FC<ButtonProps> = ({
  centerRipple,
  children,
  dataTest,
  disableRipple,
  onClick,
  onDoubleClick,
  onMouseEnter,
  onMouseLeave,
  tooltip,
  fill = '#000',
  height,
  hoverAlpha = 0.04,
  isEnabled = true,
  margin,
  style,
  width,
}) => {
  const styles = {
    base: css({
      '&.MuiButtonBase-root': css(
        {
          ...CommonStyles.MaterialCubicTransitions,
          borderRadius: 3,
          color: color.create(fill).alpha(0.4).css(), // Changes the ripple color.
          cursor: 'pointer',
          display: 'inline-block',
          height,
          margin,
          opacity: !isEnabled && onClick ? 0.5 : 1,
          outline: 'none',
          transitionDuration: '150ms',
          width,
          '&:hover': {
            background: color.create(fill).alpha(hoverAlpha).css(),
          },
        },
        style,
      ),
    }),
  };

  const elContent = (
    <ButtonBase
      sx={styles.base}
      data-test={dataTest}
      disabled={!isEnabled}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      component={'div'}
      centerRipple={centerRipple}
      disableRipple={disableRipple}
    >
      {children}
    </ButtonBase>
  );

  return tooltip ? <Tooltip title={tooltip}>{elContent}</Tooltip> : elContent;
};
